
import bodytext from '@/mixins/bodytext'
import PediDetailSVG from '@/components/svg/pedigree/PediDetailSVG.vue'
import PediMDetailSVG from '@/components/svg/pedigree/PediMDetailSVG.vue'
export default {
  components: {
    PediDetailSVG,
    PediMDetailSVG,
  },
  mixins: [bodytext],
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      tl: null,
    }
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      scrollTrigger: {
        trigger: this.$refs.pedigree,
        toggleActions: 'play reset play reverse',
        start: 'top center',
      },
    })
    this.tl.scrollTrigger.disable()

    if (this.$refs.heading) {
      const heading = new this.$SplitText(this.$refs.heading, { type: 'words' })
      this.tl.from(heading.words, { translateX: 64, duration: 0.1, stagger: 0.05 }, '<')
    }

    if (this.$refs.body) {
      const words = new this.$SplitText(this.$refs.body, { type: 'chars, words' })
      this.tl.from(words.chars, { delay: 0.3, opacity: 0.3, color: '#42795C', duration: 0.3, stagger: 0.01 }, '<')
    }

    if (this.$screen.lg) {
      const cogTL = this.$gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.SVG.$el,
          toggleActions: 'play reset play reset',
          start: 'top center',
        },
      })
      cogTL.to(this.$refs.SVG.$el.querySelector('.cog1'), { duration: 1, rotate: `+=15`, transformOrigin: 'center', repeat: -1, repeatRefresh: true, ease: 'expo.out' }, 0)
    }
    this.$emit('sectionMounted')
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
  },
}
